
          @use "sass:math";
          @import "~@/assets/scss/variables.scss";
          @import "~@/assets/scss/mixins/misc.scss";
        

@import "src/assets/scss/variables";
.hm-table {
  width: 100%;
  border-spacing: 0;
  border-style: hidden;
  border-collapse: collapse;
  &__title {
    margin: 0 0 10px 0;
  }
  &__wrap {
    border-radius: 4px;
    box-shadow: 0 0 0 1px #ddd;
    width: 100%;
    overflow-x: auto;
  }
  td, th {
    font-weight: normal;
    padding: 5px;
    border: 1px #ddd solid;
  }
  &_error {
    box-shadow: 0 0 0 1px $color-red;
    td, th {
      border: 1px $color-red solid;
    }
  }
}
.action {
  width: 35px;
  div {
    cursor: pointer;
  }
}
.add-row {
  margin: 20px 0 0 0;
}
.edited-item ::v-deep .hm-form {
  &__element {
    margin: 26px 0 0 0;
    &:first-child {
      margin: 0;
    }
    p {
      margin: 0 0 18px 0;
    }
    a {
      color: #9466ff;
    }
    &--hidden {
      display: none;
    }
    &-label {
      margin: 0 0 8px 0;
      transition: all ease 0.3s;
    }
    &-hint {
      margin: 8px 0 0 0;
      font-size: 12px;
      line-height: 16px;
      transition: all ease 0.3s;
    }
    &-wrapper_error {
      .hm-form__element-label, .hm-form__element-hint {
        color: $color-red;
      }
      .esmp-upload-drag {
        border-color: #ff0c0ccc;
      }
      .esmp-input .esmp-input-element,
      .esmp-textarea__input,
      .esmp-select-head {
        border: 1px #ff0c0ccc solid;
      }
    }
  }
}
