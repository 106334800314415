@import '~@esmpfrontend/ui/src/assets/scss/variables';

$base-font-family: 'Rostelecom Basis', sans-serif;

$color-black-op-25: rgba($color-black, .25);
$color-black-op-40: rgba($color-black, .4);
$color-black-op-70: rgba($color-black, .7);
$color-red: #f9230A;
$color-client-portal-logo: $primary-color-hover;
$color-client-portal-logo-op-60: rgba($color-client-portal-logo, .6);
$color-service-manager-logo: #ffb608;
$color-green-status: #26da72; // TODO: удалить
$color-green-status-op-15: rgba($color-green-status, .15); // TODO: удалить
$color-green-status-op-60: rgba($color-green-status, .6); // TODO: удалить
$color-error-night-op-60: rgba($color-error-night, .6); // в библиотеку
$color-gray-status: #737882; // TODO: удалить
$color-gray-status-op-15: rgba($color-gray-status, .15); // TODO: удалить
$color-gray-status-op-60: rgba($color-gray-status, .6); // TODO: удалить
$color-white-status: #e3e3e5; // TODO: удалить
$color-yellow-status: #FFB608; // TODO: удалить

$color-approve-red: #f7d9d9;
$color-approve-green: #d1efdd;

$color-main-accent-gradient-1: $primary-color;
$color-main-accent-gradient-2: $secondary-color;

// Main variables
//$base-animation-time: 0.3s;
$sidebar-width: 256px;
$sidebar-collapse-width: 56px;
$header-height: 56px;
$draggable-element-width: 40px;
$controls-width: 335px;

// TODO: перенести тени в UI, сделать миксин
$base-shadow-color: rgb($color-black, 0.1);
$base-shadow: 0 0 1px $base-shadow-color;
$base-shadow-l: $base-shadow, 0 36px 36px $base-shadow-color;

$base-gutter: 12px;
$base-accent-linear-gradient: linear-gradient(180deg, $color-main-accent-gradient-1 0%, $color-main-accent-gradient-2 86.67%);
$base-accent-radial-gradient: radial-gradient($color-main-accent-gradient-1, $color-main-accent-gradient-2);

$font-size-base: 14px;
$font-size-small: 12px;

$gap: 40px;
$right-column-width: 335px;

